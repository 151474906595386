import type { DebugPoint } from './Experience';

export type ExperienceEvent = StartEvent | StopEvent;

export type StartEvent = BaseExperienceEvent & {
	action: 'taskStart';
	timeout?: number;
};

export type StopEvent = SuccessEvent | FailEvent | AbortEvent;

type BaseStopEvent = BaseExperienceEvent & {
	duration: number;
	activeDuration: number;
	adjustedDuration: number;
};

export type SuccessEvent = BaseStopEvent & {
	action: 'taskSuccess';
};

export type FailEvent = BaseStopEvent & {
	action: 'taskFail';
	debugPoints?: DebugPoint[];
	error: Error;
};

export type AbortEvent = BaseStopEvent & {
	action: 'taskAbort';
	reason: string;
	checkForTimeout: boolean;
};

export type ExperienceAttributes = {
	[key: string]: boolean | null | number | string | undefined | Array<number | string>;
};

type BaseExperienceEvent = {
	action: string;
	name: string;
	id: string;
	startTime: number;
	attributes?: ExperienceAttributes;
};

export const isStop = (event: ExperienceEvent): boolean =>
	event.action === 'taskSuccess' || event.action === 'taskAbort' || event.action === 'taskFail';

export const hasName = (event: ExperienceEvent, ...names: string[]): boolean =>
	names.includes(event.name);
