import gql from 'graphql-tag';

import { ConfluenceWorkspaceAri, IdentityUserAri } from '@atlassian/ari';

import { getPreloaderFnContext, query } from '@confluence/query-preloader-tools';
import { getAGGClient } from '@confluence/graphql';

import { preloadGlobalApps } from './GlobalItems/AppsItem/preloadGlobalApps';

export const preloadGlobalNavigationComponent = async () => {
	const { cloudId, userId, activationId } = await getPreloaderFnContext();

	const promises: Promise<any>[] = [preloadGlobalApps()];

	if (userId) {
		promises.push(
			query({
				query: preloadGlobalNavigationComponentQuery,
				context: {
					headers: {
						'Atl-Client-Name': 'confluence-frontend',
					},
				},
				variables: {
					entityAri: ConfluenceWorkspaceAri.create({ siteId: cloudId, activationId }).toString(),
					ownerAri: IdentityUserAri.create({ userId }).toString(),
				},
				client: getAGGClient(),
			}),
		);
	}

	return Promise.all(promises);
};

const preloadGlobalNavigationComponentQuery = gql`
	query preloadGlobalNavigationComponentQuery($entityAri: ID!, $ownerAri: ID!) {
		settings_navigationCustomisation(entityAri: $entityAri, ownerAri: $ownerAri) {
			sidebar {
				nodes {
					menuId
					visible
				}
			}
		}
	}
`;
