import type { ReactNode } from 'react';
import { Component } from 'react';

import { liftPromiseState, isResolved, getValue } from '@confluence/lifted-promise';

import type { SessionDataType } from './SessionDataTypes';
import { processSessionData } from './processSessionData';
import { loadSessionData } from './loadSessionData';

/**
 * Request session data. Can be used to prefetch session data or access it in
 * non-React code.
 */
export const getSessionData = (): Promise<SessionDataType> => {
	// Not using memorize here because we want to reset promise in test
	if (window['__SITEINFORMATION_PROMISE__']) {
		return window['__SITEINFORMATION_PROMISE__'];
	}
	return (window['__SITEINFORMATION_PROMISE__'] = liftPromiseState(
		loadSessionData().then(processSessionData),
	));
};

/**
 * Returns cloud id synchronously, can be null if this is called too early, before session data is available
 */
export const getCloudId = () => {
	const sessionDataPromise = getSessionData();
	if (isResolved(sessionDataPromise)) {
		return getValue(sessionDataPromise)?.cloudId;
	} else {
		// Session data is not loaded yet, return null
		return null;
	}
};

export type SessionDataChildrenFn = (sessionDataOrLoading: SessionDataType) => ReactNode;
export type SessionDataChildrenLoadingFn = (
	sessionDataOrLoading: SessionDataType | { loading: true },
) => ReactNode;

export type SessionDataProps =
	| {
			renderChildrenWhenLoading?: false;
			children: SessionDataChildrenFn;
	  }
	| {
			/**
			 * Render children when waiting for the query response
			 */
			renderChildrenWhenLoading: true;
			children: SessionDataChildrenLoadingFn;
	  };

type State = {
	sessionData?: SessionDataType;
};

/**
 * Usage:
 *
 * ```jsx
 * <SessionData>
 *   {sessionData => (
 *     <MyComponent
 *       cloudId={sessionData.cloudId}
 *       userId={sessionData.userId}
 *       featureFlags={sessionData.featureFlags}
 *     />
 *   )}
 * </SessionData>
 * ```
 * @deprecated Prefer `useSessionData() Hook`
 */
export class SessionData extends Component<SessionDataProps, State> {
	private _mount: boolean;

	constructor(props: SessionDataProps) {
		super(props);

		const promise = getSessionData();

		this._mount = true;

		if (isResolved(promise)) {
			this.state = {
				sessionData: getValue(promise),
			};
		} else {
			void promise.then((sessionData) => {
				if (this._mount) {
					this.setState({
						sessionData,
					});
				}
			});
		}
	}

	readonly state: Readonly<State> = {};

	componentWillUnmount() {
		this._mount = false;
	}

	render() {
		const { sessionData } = this.state;

		if (sessionData) {
			return this.props.children(sessionData);
		}

		if (this.props.renderChildrenWhenLoading === true) {
			return this.props.children({ loading: true });
		}

		return null;
	}
}
