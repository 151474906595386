import { defineMessages } from 'react-intl-next';

import type { CustomField } from '@atlaskit/editor-common/extensions';

import { fg } from '@confluence/feature-gating';

import type { FieldTransformer } from './types';

const i18n = defineMessages({
	fieldRootLabel: {
		id: 'fabric-extension-lib.field-mappings.pagetree.field.root.label',
		description:
			'New label for the "Root page" configuration field for Content tree (Page tree) macro to encompass all content types. Specifies the root from which the content tree renders.',
		defaultMessage: 'Root content',
	},
	fieldSpacesLabel: {
		id: 'fabric-extension-lib.field-mappings.pagetree.field.spaces.label',
		description:
			'New label for "Restrict to this Space Key" configuration field for Content tree (Page tree) macro, should match the original string but lowercased (id: com.atlassian.confluence.plugins.pagetree.pagetree.param.spaces.label in Confluence Monolith)',
		defaultMessage: 'Restrict to this space key',
	},
	fieldSortLabel: {
		id: 'fabric-extension-lib.field-mappings.pagetree.field.sort.label',
		description:
			'New label for the "Sort Pages by" configuration field for Content tree (Page tree) macro to encompass all content types.',
		defaultMessage: 'Sort content by',
	},
	fieldExcerptLabel: {
		id: 'fabric-extension-lib.field-mappings.pagetree.field.excerpt.label',
		description:
			'New label for the "Include Excerpts in Page Tree" configuration checkbox for Content tree (Page tree) macro to encompass all content types.',
		defaultMessage: 'Include excerpts in content tree',
	},
	fieldReverseLabel: {
		id: 'fabric-extension-lib.field-mappings.pagetree.field.reverse.label',
		description:
			'New label for "Reverse Order" configuration checkbox for Content tree (Page tree) macro, should match the original string but lowercased (id: com.atlassian.confluence.plugins.pagetree.pagetree.param.reverse.label in Confluence Monolith)',
		defaultMessage: 'Reverse order',
	},
	fieldReverseDescription: {
		id: 'fabric-extension-lib.field-mappings.pagetree.field.reverse.description',
		description:
			'New description underneath the "Reverse order" checkbox to describe its function, updated to reflect the new "Sort content by" field label',
		defaultMessage: 'Used only in conjunction with the “Sort content by” field.',
	},
	fieldSearchBoxLabel: {
		id: 'fabric-extension-lib.field-mappings.pagetree.field.searchBox.label',
		description:
			'New label for the "Include Search Box above Page Tree" configuration checkbox for Content tree (Page tree) macro to encompass all content types.',
		defaultMessage: 'Include search box above content tree',
	},
	fieldExpandCollapseAllLabel: {
		id: 'fabric-extension-lib.field-mappings.pagetree.field.expandCollapseAll.label',
		description:
			'New label for "Show Expand/Collapse Links" configuration checkbox for Content tree (Page tree) macro, should match the original string but lowercased (id: com.atlassian.confluence.plugins.pagetree.pagetree.param.expandCollapseAll.label in Confluence Monolith)',
		defaultMessage: 'Show expand/collapse links',
	},
	fieldExpandCollapseAllDescription: {
		id: 'fabric-extension-lib.field-mappings.pagetree.field.expandCollapseAll.description',
		description:
			'Description underneath the "Show expand/collapse links" checkbox to describe its function, updated to reference "content" tree (rather than a page tree)',
		defaultMessage:
			'Displays the “expand all” and “collapse all” links at the top of the content tree.',
	},
	fieldStartDepthLabel: {
		id: 'fabric-extension-lib.field-mappings.pagetree.field.startDepth.label',
		description:
			'New label for the "Start Depth" configuration field for Content tree (Page tree) macro, allows users to enter a number for maximum depth of child items in the tree.',
		defaultMessage: 'Tree depth',
	},
	fieldStartDepthDescription: {
		id: 'fabric-extension-lib.field-mappings.pagetree.field.startDepth.description',
		description:
			'Description underneath the "Tree depth" configuration field to describe its purpose',
		defaultMessage: 'The number of levels of child items displayed.',
	},
});

export const pagetree: FieldTransformer<{ root: string | string[] }> = {
	transformFields: async (fields, _, intl) => {
		return fields.map((field) => {
			let fieldResult = { ...field };

			if (field.name === 'root') {
				fieldResult = {
					...fieldResult,
					isMultiple: false,
					type: 'custom',
					options: {
						resolver: {
							type: 'rootPage',
						},
					},
				} as CustomField;
			}

			if (fg('confluence_live_pages_macro_copy_updates')) {
				let newMacroCopies = {};

				switch (field.name) {
					case 'root':
						newMacroCopies = { label: intl.formatMessage(i18n.fieldRootLabel) };
						break;
					case 'spaces':
						newMacroCopies = { label: intl.formatMessage(i18n.fieldSpacesLabel) };
						break;
					case 'sort':
						newMacroCopies = { label: intl.formatMessage(i18n.fieldSortLabel) };
						break;
					case 'excerpt':
						newMacroCopies = { label: intl.formatMessage(i18n.fieldExcerptLabel) };
						break;
					case 'reverse':
						newMacroCopies = {
							label: intl.formatMessage(i18n.fieldReverseLabel),
							description: intl.formatMessage(i18n.fieldReverseDescription),
						};
						break;
					case 'searchBox':
						newMacroCopies = { label: intl.formatMessage(i18n.fieldSearchBoxLabel) };
						break;
					case 'expandCollapseAll':
						newMacroCopies = {
							label: intl.formatMessage(i18n.fieldExpandCollapseAllLabel),
							description: intl.formatMessage(i18n.fieldExpandCollapseAllDescription),
						};
						break;
					case 'startDepth':
						newMacroCopies = {
							label: intl.formatMessage(i18n.fieldStartDepthLabel),
							description: intl.formatMessage(i18n.fieldStartDepthDescription),
						};
						break;
				}

				fieldResult = {
					...fieldResult,
					...newMacroCopies,
				};
			}

			return fieldResult;
		});
	},
};
