import React, { type ReactNode } from 'react';
import { IconButton } from '@atlaskit/button/new';
import { cssMap } from '@atlaskit/css';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import { Box } from '@atlaskit/primitives/compiled';
import { token } from '@atlaskit/tokens';
import { type ModalExtension } from './util';
import { getAkModalWidth } from '../utils';

const styles = cssMap({
	modalWrapper: {
		paddingBottom: token('space.300'),
	},
});

interface ForgeExtensionPointModalProps {
	children: ReactNode;
	modalExtension: ModalExtension;
}

export const ForgeExtensionPointModal = (props: ForgeExtensionPointModalProps) => {
	const {
		closeModalExtension,
		title,
		modalWidth,
		shouldCloseOnOverlayClick,
		shouldCloseOnEscapePress,
	} = props.modalExtension;

	const akModalWidth = modalWidth ? getAkModalWidth(modalWidth) : undefined;

	return (
		<Modal
			shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
			shouldCloseOnEscapePress={shouldCloseOnEscapePress}
			onClose={closeModalExtension}
			width={akModalWidth}
		>
			<ModalHeader>
				<ModalTitle>{title}</ModalTitle>
				<IconButton
					appearance="subtle"
					icon={CrossIcon}
					label="Close Modal"
					onClick={closeModalExtension}
				/>
			</ModalHeader>
			<ModalBody>
				<Box xcss={styles.modalWrapper}>{props.children}</Box>
			</ModalBody>
		</Modal>
	);
};
