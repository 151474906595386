import { query } from '@confluence/query-preloader-tools';

import { WHATS_NEW_IN_CONFLUENCE } from '../../constants/onboarding-state/onboarding-constants';

import { getOnboardingStateGQL } from './OnboardingStatus.graphql';

export const preloadWhatsNewInConfluenceOnboardingState = () => {
	return query({
		query: getOnboardingStateGQL,
		variables: {
			key: [
				...Object.values(WHATS_NEW_IN_CONFLUENCE),
				'modernize-confluence-ipm-modal:hasDismissedModernizeConfluenceIpmModal',
			],
		},
	});
};
