import { css } from '@compiled/react';
import type { FC, ReactNode } from 'react';
import React from 'react';

import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/new';
import type { ButtonProps } from '@atlaskit/button/new';
import { xcss, Box } from '@atlaskit/primitives';

import { shouldShowMobileWeb } from '@confluence/mobile-detection';

import { ErrorPageImage } from './ErrorPageImage';

const ErrorContainerStyles = css({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: token('elevation.surface'),
	height: '90vh',
});

const ErrorContainerMobileStyles = css({
	height: '100vh',
});

const ErrorContainer = ({
	isMobile,
	children,
}: {
	isMobile: boolean;
	children?: React.ReactNode;
}) => {
	return (
		<div
			data-vc="not-found-container"
			css={[ErrorContainerStyles, isMobile && ErrorContainerMobileStyles]}
		>
			{children}
		</div>
	);
};

const ErrorPageWrapperStyles = css({
	display: 'flex',
	justifyContent: 'center',
	maxWidth: '700px',
	flexDirection: 'row',
});

const ErrorPageWrapperMobileStyles = css({
	flexDirection: 'column',
});

const ErrorPageWrapper = ({
	isMobile,
	children,
}: {
	isMobile: boolean;
	children?: React.ReactNode;
}) => {
	return (
		<div css={[ErrorPageWrapperStyles, isMobile && ErrorPageWrapperMobileStyles]}>{children}</div>
	);
};

const AlignContentStyles = css({
	alignSelf: 'center',
	textAlign: 'left',
	margin: token('space.500'),
});

const AlignContentMobileStyles = css({
	textAlign: 'center',
	margin: 'initial',
});

const AlignContent = ({
	isMobile,
	children,
}: {
	isMobile: boolean;
	children?: React.ReactNode;
}) => {
	return <div css={[AlignContentStyles, isMobile && AlignContentMobileStyles]}>{children}</div>;
};

const ErrorTitleStyles = css({
	margin: `${token('space.300')} 0 ${token('space.300')}`,
	font: token('font.heading.xxlarge'),
	fontWeight: token('font.weight.regular'),
	width: '460px',
	color: token('color.text'),
});

const ErrorTitleMobileStyles = css({
	font: token('font.heading.xlarge'),
	width: '327px',
});

const ErrorTitle = ({ isMobile, children }) => {
	return (
		// eslint-disable-next-line @atlaskit/design-system/use-heading
		<h1 css={[ErrorTitleStyles, isMobile && ErrorTitleMobileStyles]}>{children}</h1>
	);
};

const ErrorMessageStyles = css({
	font: token('font.body'),
	fontWeight: token('font.weight.regular'),
	color: token('color.text'),
	maxWidth: '415px',
	margin: 'unset',
});

const ErrorMessageMobileStyles = css({
	font: token('font.body.large'),
	maxWidth: '327px',
	margin: '0px auto',
});

const ErrorMessage = ({
	isMobile,
	children,
}: {
	isMobile: boolean;
	children?: React.ReactNode;
}) => {
	return <div css={[ErrorMessageStyles, isMobile && ErrorMessageMobileStyles]}>{children}</div>;
};

const ButtonContainerStyles = css({
	display: 'flex',
	justifyContent: 'left',
});

const ButtonContainerMobileStyles = css({
	justifyContent: 'center',
});
const ButtonContainer = ({
	isMobile,
	children,
}: {
	isMobile: boolean;
	children?: React.ReactNode;
}) => {
	return (
		<div css={[ButtonContainerStyles, isMobile && ButtonContainerMobileStyles]}>{children}</div>
	);
};

const primaryButtonStyles = xcss({
	cursor: 'pointer',
	marginTop: 'space.250',
});

const mobilePrimaryButtonStyles = xcss({
	cursor: 'pointer',
	marginTop: 'space.200',
});

const secondaryButtonStyles = xcss({
	cursor: 'pointer',
	marginTop: 'space.250',
	marginLeft: 'space.200',
});

const mobileSecondaryButtonStyles = xcss({
	cursor: 'pointer',
	marginTop: 'space.200',
	marginLeft: 'space.100',
});

const childrenStyles = xcss({
	// @ts-ignore TODO: refactor to remove need for hardcoded `-90px`
	marginTop: '-90px',
	marginInline: 'space.250',
	marginBottom: 'space.250',
	maxWidth: '700px',
});

const mobileChildrenStyles = xcss({
	// @ts-ignore TODO: refactor to remove need for hardcoded `-74px`
	marginTop: '-74px',
	marginInline: 'space.250',
	marginBottom: 'space.250',
	maxWidth: '700px',
});

type ErrorPageComponentProps = {
	errorImage?: string;
	errorImageDark?: string;
	errorTitle: ReactNode | string;
	errorMessage: ReactNode;
	primaryButtonProps?: ButtonProps;
	secondaryButtonProps?: ButtonProps;
	children?: React.ReactNode;
};

export const ErrorPageComponent: FC<ErrorPageComponentProps> = ({
	errorImage,
	errorImageDark,
	errorTitle,
	errorMessage,
	primaryButtonProps,
	secondaryButtonProps,
	children,
}) => {
	const isMobile = shouldShowMobileWeb();

	return (
		<ErrorContainer isMobile={isMobile}>
			<ErrorPageWrapper isMobile={isMobile}>
				<AlignContent isMobile={isMobile}>
					{errorImage && (
						<ErrorPageImage src={errorImage} srcDark={errorImageDark} isMobile={isMobile} />
					)}
				</AlignContent>
				<AlignContent isMobile={isMobile}>
					<ErrorTitle isMobile={isMobile}>{errorTitle}</ErrorTitle>
					<ErrorMessage isMobile={isMobile}>{errorMessage}</ErrorMessage>
					{(primaryButtonProps?.children || secondaryButtonProps?.children) && (
						<ButtonContainer isMobile={isMobile}>
							{primaryButtonProps?.children && (
								<Box xcss={isMobile ? mobilePrimaryButtonStyles : primaryButtonStyles}>
									<Button appearance="primary" {...primaryButtonProps} />
								</Box>
							)}
							{secondaryButtonProps?.children && (
								<Box xcss={isMobile ? mobileSecondaryButtonStyles : secondaryButtonStyles}>
									<Button appearance="default" {...secondaryButtonProps} />
								</Box>
							)}
						</ButtonContainer>
					)}
				</AlignContent>
			</ErrorPageWrapper>
			{React.Children.count(children) ? (
				<Box xcss={isMobile ? mobileChildrenStyles : childrenStyles}>{children}</Box>
			) : null}
		</ErrorContainer>
	);
};
