import { query, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { themeSSRManager } from '@confluence/ssr-utilities';
import { getEffectiveThemeState } from '@confluence/theming-utils';

import type {
	UserSettingsQuery as UserSettingsQueryType,
	UserSettingsQueryVariables as UserSettingsQueryVariablesType,
} from './__types__/UserSettingsQuery';
import { UserSettingsQuery } from './UserSettingsQuery.graphql';

export const API_DEFAULT_THEME_STATE = 'default';

export const preloadUserSettingsForSSR = async ({ themeState }: { themeState?: string }) => {
	if (themeState) {
		themeSSRManager.setThemeStateBasedOnSavedThemeState(
			getEffectiveThemeState(decodeURIComponent(themeState)),
		);
	}

	const { isLicensed } = await getPreloaderFnContext();

	const response = await query<UserSettingsQueryType, UserSettingsQueryVariablesType>({
		query: UserSettingsQuery,
		variables: {
			isNotLicensed: !isLicensed,
		},
	});

	const theme = response?.data?.userPreferences?.theme;
	// Setting colorMode for user theme
	if (theme) {
		themeSSRManager.setThemeStateBasedOnSavedThemeState(getEffectiveThemeState(theme));
	}

	return response;
};
