import gql from 'graphql-tag';

import { SpacesListSpaceFragment } from './SpacesListSpaceFragment.fragment';

export const StarredSpacesQuery = gql`
	query StarredSpacesQuery {
		spaces(favourite: true, first: 99, status: "current") {
			nodes {
				...SpacesListSpaceFragment
			}
		}
	}
	${SpacesListSpaceFragment}
`;

export const MyVisitedSpacesQuery = gql`
	query MyVisitedSpacesQuery {
		myVisitedSpaces(limit: 26) {
			nodes {
				spaces {
					...SpacesListSpaceFragment
				}
			}
		}
	}
	${SpacesListSpaceFragment}
`;

export const MyVisitedSpacesWithCurrentSpaceQuery = gql`
	query MyVisitedSpacesWithCurrentSpaceQuery($selectedSpaceKey: String) {
		space(key: $selectedSpaceKey) {
			...SpacesListSpaceFragment
			currentUser {
				isFavourited
			}
		}
		myVisitedSpaces(limit: 26) {
			nodes {
				spaces {
					...SpacesListSpaceFragment
				}
			}
		}
	}
	${SpacesListSpaceFragment}
`;

export const AnonymousSpacesQuery = gql`
	query AnonymousSpacesQuery {
		spaces(first: 10) {
			nodes {
				...SpacesListSpaceFragment
			}
		}
	}
	${SpacesListSpaceFragment}
`;

export const SearchSpacesQuery = gql`
	query SearchSpacesQuery($spaceNamePattern: String!, $isLicensed: Boolean!) {
		spaces(first: 30, status: "current", spaceNamePattern: $spaceNamePattern) {
			nodes {
				...SpacesListSpaceFragment
				currentUser @include(if: $isLicensed) {
					isFavourited
				}
			}
		}
	}
	${SpacesListSpaceFragment}
`;
