import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { getRemoteLocalStorage } from '@confluence/storage-manager';

export async function preloadLocalStorage() {
	const { userId, isLicensed } = await getPreloaderFnContext();
	return getRemoteLocalStorage({
		userId,
		isLicensed,
	});
}
