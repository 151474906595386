import React from 'react';
import type { PropsWithChildren } from 'react';
import { defineMessages, FormattedMessage, type IntlShape } from 'react-intl-next';

import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import {
	DefaultExtensionProvider,
	type ExtensionDeprecationStatus,
	type ExtensionComponentProps,
	type ExtensionHandler,
	type ExtensionHandlers,
	type ExtensionManifest,
	type ExtensionModuleNode,
	type ExtensionProvider,
} from '@atlaskit/editor-common/extensions';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { PublicPluginAPI } from '@atlaskit/editor-common/types';
import type { ADNode } from '@atlaskit/editor-common/validator';
import type { EditorActions } from '@atlaskit/editor-core';
import type { ExtensionPlugin } from '@atlaskit/editor-plugins/extension';
import { Text } from '@atlaskit/primitives';
import Link from '@atlaskit/link';

import { getAIPanelsProvider } from '@atlassian/editor-plugin-ai/AIPanels';
import type { ExtensionHandlerWithReferenceFn } from '@atlassian/editor-referentiality';

import { createChartsExtension } from '@confluence/charts/entry-points/createChartsExtension';
import { createDatabaseExtension } from '@confluence/confluence-databases-extension';
import {
	carouselExtensionProvider,
	hubElementPlaceholderExtensionProvider,
	linkCardsExtensionProvider,
	linksMenuExtensionProvider,
	sectionExtensionProvider,
	spotlightExtensionProvider,
	searchExtensionProvider,
} from '@confluence/custom-sites-extensions/entry-points/extensionProviders';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import { expVal } from '@confluence/feature-experiments';
import { fg } from '@confluence/feature-gating';
import type { FlagsStateContainer } from '@confluence/flags';
import { getMacroId } from '@confluence/macro-tracker';
import { createWhiteboardExtension } from '@confluence/whiteboard-extension';
import { createTourableRedactionExtension } from '@confluence/redactions/entry-points/extension';

import { getAutoConverter } from '../../editor-extensions';
import { isAllowedWithFeatureFlags } from '../../editor-extensions/blocklist';
import { getDefaultParamValue, unwrapMacroParams } from '../../editor-extensions/transformers';
import {
	EXTENSION_TYPE,
	DEPRECATED_MACROS,
	MACRO_DEPRECATION_INFO_URL,
} from '../../extensionConstants';
import {
	isBodiedExtension,
	getMacroTitleOverride,
	getMacroDescriptionOverride,
	type MacroConfig,
} from '../../extensions-common';
import { getRedactedMacroPlaceholderExtensionManifest } from '../../renderer-extensions/redacted-macro-placeholder/manifest';

import {
	getMissingConfigPlaceholderComponent,
	getNonRenderablePlaceholderComponent,
} from './MacroComponents';
import { nestedTableExtension } from './custom-extensions/nestedTable';
import type {
	ConfluencePageContext,
	LegacyMacroManifest,
	OnCompleteCallbackFnType,
	RendererExtensionHandlers,
} from './extensionTypes';
import { transformFormDetailsIntoFields } from './field-helpers';
import { remapCustomFields, transformParams } from './field-mappings';
import {
	buildIconObject,
	canRenderInTheEditor,
	getExtensionBodyType,
	getVisibleParameters,
	prepareMacro,
	safeGetMacroName,
	shouldShowPlaceholder,
	shouldUseMacroBrowser,
} from './manifest-helpers';
import {
	createQuickInsertModule,
	filterOutHiddenMacros,
	getSelectedBodiedExtensionNode,
} from './utils';
// resolvers
import { getAttachmentResolverFor } from './data-resolvers/attachment-resolver';
import { calendarResolver } from './data-resolvers/calendar-resolver';
import { confluenceContentResolver } from './data-resolvers/confluence-content-resolver';
import { customValueResolver } from './data-resolvers/custom-value-resolver';
import { getExtensionManifest } from './data-resolvers/getExtensionManifest';
import { labelResolver } from './data-resolvers/label-resolver';
import { spaceAndPageResolver } from './data-resolvers/space-and-page-resolver';
import { getSpaceKeyAndPageTitleContentResolver } from './data-resolvers/space-key-page-title-content-resolver';
import {
	buildResolver as buildSpacekeyResolver,
	spacekeyResolver,
} from './data-resolvers/spacekey-resolver';
import { getTemplateResolver } from './data-resolvers/template-resolver';
import { unsupportedValueResolver } from './data-resolvers/unsupported-value-resolver';
import { usernameResolver } from './data-resolvers/username-resolver';
import { widthPercentageResolver } from './data-resolvers/width-percentage-resolver';
// transformers
import { cqlDeserializer, cqlSerializer } from './cql/transformers';

const i18n = defineMessages({
	deprecatedMacroDescription: {
		id: 'fabric-extension-lib.deprecation.description',
		defaultMessage:
			'This macro has been deprecated. <alink>Find out more about this change</alink>',
		description:
			'Deprecation note that appears under the macro description in the configuration panel for certain macros that will be removed from Confluence. <alink> goes to this page: https://support.atlassian.com/confluence-cloud/docs/learn-which-macros-are-being-removed/. Please ensure the translation matches that of "macro.browser.deprecation.desc" from CONFCLOUD/confluence repository (Confluence Monolith) as they are the same.',
	},
});

// We downloaded the extension manifest
// earlier within query-preloaders
// the resulting manifest is cached
const getMacroLegacyManifest = getExtensionManifest;

// Exported for testing only
export const getMacroDeprecation = (
	macro: LegacyMacroManifest,
): ExtensionDeprecationStatus | undefined => {
	// TODO: remove this code when cleaning up deprecating_low_usage_macros FG after macro removal on Sep 25 2025
	if (
		DEPRECATED_MACROS.includes(macro.macroName) &&
		fg('platform_editor_extension_deprecation_status')
	) {
		return {
			isDeprecated: true,
			message: (
				<Text weight="medium">
					⚠️&nbsp;
					<FormattedMessage
						{...i18n.deprecatedMacroDescription}
						values={{
							alink: (...chunks) => (
								<Link href={MACRO_DEPRECATION_INFO_URL} target="_blank">
									{chunks}
								</Link>
							),
						}}
					/>
				</Text>
			),
		};
	}
	return undefined;
};

const mapLegacyManifestsToModernManifest = async (
	legacyMacros: LegacyMacroManifest[],
	pageContext: ConfluencePageContext,
	extensionHandlers: RendererExtensionHandlers,
	getMigrationExtensionManifest: Function,
	openMacroBrowserToEditExistingMacro: Function,
	openMacroBrowserForInitialConfiguration: Function,
	editorAPI: PublicPluginAPI<[ExtensionPlugin]> | undefined,
	intl: IntlShape,
	macroBrowserConfig?: MacroConfig,
	editorActions?: EditorActions,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	onCompleteCallback?: OnCompleteCallbackFnType,
	isLivePage?: boolean,
	flags?: FlagsStateContainer,
	allowLegacyMacros = false,
) => {
	const isAllowed = isAllowedWithFeatureFlags();

	return legacyMacros
		.map((macro: LegacyMacroManifest) =>
			transformLegacyMacrosToExtensionManifest(
				EXTENSION_TYPE.MACRO_CORE,
				macro,
				pageContext,
				extensionHandlers,
				!isAllowed(macro),
				openMacroBrowserToEditExistingMacro,
				openMacroBrowserForInitialConfiguration,
				editorAPI,
				intl,
				macroBrowserConfig,
				editorActions,
				createAnalyticsEvent,
				onCompleteCallback,
				isLivePage,
				allowLegacyMacros,
			),
		)
		.concat(
			getMigrationExtensionManifest(
				pageContext.contentId,
				!!editorActions,
				flags,
				createAnalyticsEvent,
			),
		);
};

// Export for testing ONLY
export const transformLegacyMacrosToExtensionManifest = (
	extensionType: string,
	macro: LegacyMacroManifest,
	pageContext: ConfluencePageContext,
	extensionHandlers: RendererExtensionHandlers,
	readonly: boolean,
	openMacroBrowserToEditExistingMacro: any,
	openMacroBrowserForInitialConfiguration: any,
	editorAPI: PublicPluginAPI<[ExtensionPlugin]> | undefined,
	intl: IntlShape,
	macroBrowserConfig?: MacroConfig,
	editorActions?: EditorActions,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	onCompleteCallback?: OnCompleteCallbackFnType,
	isLivePage?: boolean,
	allowLegacyMacros = false,
): ExtensionManifest => {
	const extensionKey = macro.macroName;

	const isEditor = !!editorActions;
	const { enabled: isConfigPanelEnabled, optedOut: excludedMacros } =
		pageContext.features.configPanel || {};

	const adfNodeType = getExtensionBodyType(macro);

	// Override title & description in manifest for certain macros in Fabric editor only
	const macroOverrides = {
		title: getMacroTitleOverride(extensionKey, intl) ?? macro.title,
		description: getMacroDescriptionOverride(extensionKey, intl) ?? macro.description,
	};
	macro = { ...macro, ...macroOverrides };

	const defaultNode: ExtensionModuleNode = {
		type: adfNodeType,
		render: () => {
			const extensionHandler = extensionHandlers[extensionType];

			return Promise.resolve((extension: PropsWithChildren<ExtensionComponentProps<any>>) => {
				// per backend restrictions, spaceKey and templateName are required in the extension's macroParams object in order for create-from-template macro to work for autogenerated blueprint index pages.
				if (extension.node.extensionKey === 'create-from-template') {
					const macroParams = extension.node.parameters?.['macroParams'];

					if (!macroParams?.spaceKey) {
						macroParams.spaceKey = { value: pageContext.spaceKey };
					}
					if (!macroParams?.templateName) {
						macroParams.templateName = macroParams.contentBlueprintId;
					}
				}

				// if an override is provided for title, update the title for extension label lozenge component
				// This is necessary to update existing macros on a page after a title change rolls out
				if (extension.node.parameters.macroMetadata && macroOverrides?.title) {
					extension.node.parameters.macroMetadata.title = macroOverrides.title;
				}

				const showPlaceholder = shouldShowPlaceholder(
					macro,
					excludedMacros,
					extension.node,
					isEditor,
				);

				if ((isConfigPanelEnabled || !isEditor) && showPlaceholder) {
					return getMissingConfigPlaceholderComponent(macro, extension.node, isEditor);
				}

				if (isConfigPanelEnabled && isEditor && !canRenderInTheEditor(macro)) {
					return getNonRenderablePlaceholderComponent(
						macro,
						getExtensionBodyType(macro),
						isLivePage,
					);
				}

				if (typeof extensionHandler === 'function') {
					return (extensionHandler as ExtensionHandler<any>)(extension.node, {
						references: extension.references,
						actions: extension.actions,
					});
				}
				return extensionHandler.render(extension.node, {
					references: extension.references,
					actions: extension.actions,
				});
			});
		},
	};

	const allowEdit = fg('platform_editor_legacy_content_macro')
		? allowLegacyMacros || !readonly
		: !readonly;
	if (
		allowEdit &&
		(getVisibleParameters(macro).length > 0 || shouldUseMacroBrowser(macro, excludedMacros))
	) {
		const editInMacroBrowser = async (data, actions) => {
			let selectedNode: ADNode | undefined;
			/**
			 * In API v1, we used to pass the whole `node` as `parameters`.
			 * We fixed it in API v2 and now only passing `parameters`.
			 * But Bodied Extensions inside Macro Browser still need the body for preview.
			 * @see DEVHELP-6152
			 */
			if (isBodiedExtension(adfNodeType)) {
				// Currently, there's no other way to get selected node without using private API.
				selectedNode = getSelectedBodiedExtensionNode(editorActions);
			}

			const currentNode = await prepareMacro(
				{
					name: macro.macroName,
					params: unwrapMacroParams(data.macroParams),
				},
				pageContext.contentId,
				selectedNode,
			);

			const defaultParameterValue = getDefaultParamValue(data.macroParams || {});
			if (defaultParameterValue) {
				const macroParams = currentNode.attrs?.parameters?.macroParams;
				if (macroParams) {
					macroParams[''] = defaultParameterValue;
				}
			}

			const macroFilter = undefined;
			const extensionAnalyticsInfo = {
				createAnalyticsEvent,
				contentId: pageContext.contentId,
			};

			const updateMacro = (newNode) => {
				selectedNode = getSelectedBodiedExtensionNode(editorActions);
				actions.doc.update(selectedNode?.attrs?.localId, () => ({
					attrs: newNode.attrs,
					marks: newNode.marks,
					content: newNode.content,
				}));
			};

			const newNode = await openMacroBrowserToEditExistingMacro(
				currentNode,
				macroBrowserConfig,
				macroFilter,
				extensionAnalyticsInfo,
				updateMacro,
			);

			// For bodiedExtension we should update the content
			if (isBodiedExtension(adfNodeType) && actions?.doc?.update) {
				actions.doc.update(selectedNode?.attrs?.localId, () => ({
					attrs: newNode.attrs,
					marks: newNode.marks,
					content: newNode.content,
				}));
				return;
			}
			return newNode.attrs.parameters || {};
		};

		let params;

		defaultNode.update = async (data, actions) => {
			// The adf parameters for macros look like:
			// {
			//    macroParams: {
			//        q: {value: 1},
			//        search: {value: 'my keyword'}
			//    },
			//    macroMetadata: {}
			// }

			// The only editable bit is what is inside the `macroParams`. This method unwraps the params and pass it to
			// the context panel for editing, and then wraps it back after saving.
			const transformBefore = (parameters) => {
				params = unwrapMacroParams(parameters.macroParams);
				return transformParams(
					'transformBefore',
					macro,
					params,
					pageContext,
					undefined,
					createAnalyticsEvent,
				);
			};

			const transformAfter = async (initialParameters) => {
				const parameters = transformParams(
					'transformAfter',
					macro,
					initialParameters,
					pageContext,
					undefined,
					createAnalyticsEvent,
				);

				// macros might have metadata changed based on macroParams so we need to fetch again. The rule changes on
				// each of them so we can't do it client side.
				const macroDefinition: any = {
					name: macro.macroName,
					params: parameters,
				};
				const defaultParameterValue = getDefaultParamValue(parameters);
				if (defaultParameterValue) {
					macroDefinition.defaultParameterValue = defaultParameterValue;
				}
				const node = await prepareMacro(macroDefinition, pageContext.contentId, null, intl);

				// add a new macroId if we don't already have one
				if (node.attrs.parameters) {
					if (!node.attrs.parameters.macroMetadata) {
						node.attrs.parameters.macroMetadata = {};
					}

					if (!node.attrs.parameters.macroMetadata['macroId']) {
						node.attrs.parameters.macroMetadata['macroId'] = {
							value: getMacroId(node.attrs.parameters),
						};
					}
				}

				return node.attrs.parameters || {};
			};

			if (!isConfigPanelEnabled || shouldUseMacroBrowser(macro, excludedMacros)) {
				return editInMacroBrowser(data, actions);
			}

			return actions!.editInContextPanel(transformBefore, transformAfter);
		};

		if (getVisibleParameters(macro).length > 0) {
			defaultNode.getFieldsDefinition = () => {
				const fields = macro.formDetails.parameters.map((params) =>
					transformFormDetailsIntoFields(params, {
						pluginKey: macro.pluginKey,
						macroName: safeGetMacroName(macro),
					}),
				);

				return remapCustomFields(
					macro,
					fields,
					pageContext,
					intl,
					params,
					undefined,
					createAnalyticsEvent,
					isLivePage,
				);
			};
		}
	}

	return {
		type: extensionType,
		key: extensionKey,
		title: macro.title,
		description: macro.description,
		deprecation: getMacroDeprecation(macro),
		icons: buildIconObject(macro),
		documentationUrl: macro.formDetails.documentationUrl || undefined,
		modules: {
			quickInsert:
				!readonly && editorActions
					? createQuickInsertModule(
							macro,
							pageContext,
							openMacroBrowserForInitialConfiguration,
							editorAPI,
							intl,
							macroBrowserConfig,
							editorActions,
							createAnalyticsEvent,
							onCompleteCallback,
							isLivePage,
						)
					: [],
			nodes: {
				default: defaultNode,
			},
			fields: {
				custom: {
					attachment: {
						resolver: getAttachmentResolverFor(macro, pageContext),
					},
					spacekey: {
						resolver: spacekeyResolver(macro),
					},
					username: {
						resolver: usernameResolver,
					},
					label: {
						resolver: labelResolver,
					},
					'confluence-content-by-id': {
						resolver: confluenceContentResolver,
					},
					'confluence-content': {
						resolver: unsupportedValueResolver(getSpaceKeyAndPageTitleContentResolver()),
					},
					spaceAndPage: {
						resolver: spaceAndPageResolver,
					},
					spaceKeyAndPageTitleContent: {
						resolver: getSpaceKeyAndPageTitleContentResolver(),
					},
					rootPage: {
						resolver: customValueResolver({
							resolver: getSpaceKeyAndPageTitleContentResolver(),
							values: ['@self', '@home', '@parent', '@none'],
						}),
					},
					calendar: {
						resolver: calendarResolver,
					},
					template: {
						resolver: getTemplateResolver(pageContext),
					},
					blogRecentlyUpdatedSpace: {
						resolver: customValueResolver({
							/* blog posts and recently updated macros don't support currentSpace() */
							resolver: buildSpacekeyResolver(false),
							values: ['@self', '@personal', '@global', '@favorite', '@favourite', '@all', '*'],
						}),
					},
					contributorsSpaceParamResolver: {
						resolver: customValueResolver({
							resolver: buildSpacekeyResolver(false),
							values: ['@personal', '@global', '@all'],
						}),
					},
					recentlyUpdatedDashboardSpaceKeyResolver: {
						resolver: customValueResolver({
							resolver: buildSpacekeyResolver(false),
							values: ['*'],
						}),
					},
					widthPercentage: {
						resolver: widthPercentageResolver(intl),
					},
				},
				fieldset: {
					cql: {
						serializer: cqlSerializer,
						deserializer: cqlDeserializer,
					},
				},
			},
		},
	};
};

export const getConfluenceMacrosExtensionProviderForEditor = async (
	pageContext: ConfluencePageContext,
	extensionHandlers: ExtensionHandlers,
	editorAPI: PublicPluginAPI<[ExtensionPlugin]> | undefined,
	editorActions: EditorActions,
	macroBrowserConfig: MacroConfig,
	getMigrationExtensionManifest: Function,
	openMacroBrowserToEditExistingMacro: Function,
	openMacroBrowserForInitialConfiguration: Function,
	intl: IntlShape,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	onCompleteCallback?: OnCompleteCallbackFnType,
	isLivePage?: boolean,
	flags?: FlagsStateContainer,
	experienceTracker?: ExperienceTrackerAPI,
	contentType?: string,
	allowCreateDatabaseExtension: boolean = false,
	allowDatabaseQuickInsert: boolean = false,
	allowCreateWhiteboardExtension: boolean = false,
	allowLegacyMacros: boolean = false,
): Promise<ExtensionProvider> => {
	const [legacyManifests, macroAutoConverters] = await Promise.all([
		getMacroLegacyManifest(pageContext),
		getAutoConverter(macroBrowserConfig),
	]);

	const legacyManifestMacros = isLivePage
		? filterOutHiddenMacros(legacyManifests.macros)
		: legacyManifests.macros;

	let modernManifests = await mapLegacyManifestsToModernManifest(
		legacyManifestMacros,
		pageContext,
		extensionHandlers,
		getMigrationExtensionManifest,
		openMacroBrowserToEditExistingMacro,
		openMacroBrowserForInitialConfiguration,
		editorAPI,
		intl,
		macroBrowserConfig,
		editorActions,
		createAnalyticsEvent,
		onCompleteCallback,
		isLivePage,
		flags,
		allowLegacyMacros,
	);

	modernManifests = modernManifests.filter((manifest) => manifest.key !== 'toc');

	const macro = legacyManifestMacros.find((m) => m.macroName === 'toc');
	const { tableOfContentsExtension } = await import(
		/* webpackChunkName: "loadable-TableOfContents" */ './custom-extensions/TableOfContents'
	);

	const createTableOfContentsExtension = tableOfContentsExtension({
		macro,
		pageContext,
		extensionHandlers,
		editorAPI,
		openMacroBrowserForInitialConfiguration,
		macroBrowserConfig,
		editorActions,
		createAnalyticsEvent,
		intl,
		onCompleteCallback,
		isLivePage,
	});

	modernManifests.push(createTableOfContentsExtension);

	const { redactionExtension } = await import(
		/* webpackChunkName: "loadable-Redaction" */ './custom-extensions/Redaction'
	);

	if (fg('confluence_dlp_content_redaction')) {
		if (fg('dlp_cc-redactions-tour')) {
			modernManifests.push(createTourableRedactionExtension({ contentId: pageContext?.contentId }));
		} else {
			modernManifests.push(redactionExtension());
		}
	}

	if (fg('platform_editor_use_nested_table_pm_nodes')) {
		modernManifests.push(nestedTableExtension);
	}

	if (allowCreateDatabaseExtension) {
		modernManifests.push(
			createDatabaseExtension({
				pageId: pageContext.contentId,
				spaceKey: pageContext.spaceKey!,
				spaceId: pageContext.spaceId!,
				intl,
				flags,
				createAnalyticsEvent,
				experienceTracker,
				contentType,
				allowDatabaseQuickInsert:
					allowDatabaseQuickInsert && !fg('confluence_frontend_databases_opt-out'),
				isEdit: true,
			}),
		);
	}

	if (
		allowCreateWhiteboardExtension &&
		pageContext.spaceId &&
		pageContext.spaceKey &&
		expVal('create_whiteboard_via_slash_command', 'enabled', false)
	) {
		modernManifests.push(
			createWhiteboardExtension({
				pageId: pageContext.contentId,
				spaceKey: pageContext.spaceKey,
				spaceId: pageContext.spaceId,
				intl,
				flags,
				experienceTracker,
				createAnalyticsEvent,
				contentType,
				allowWhiteboardQuickInsert: allowCreateWhiteboardExtension,
			}),
		);
	}

	return new DefaultExtensionProvider(modernManifests, [
		(link: string) => {
			const result = macroAutoConverters(link);

			if (result) {
				return result;
			}
		},
	]);
};

export const getConfluenceMacrosExtensionProviderForRenderer = async (
	pageContext: ConfluencePageContext,
	extensionHandlers: RendererExtensionHandlers,
	getMigrationExtensionManifest: Function,
	openMacroBrowserToEditExistingMacro: Function,
	openMacroBrowserForInitialConfiguration: Function,
	intl: IntlShape,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	providerFactory?: ProviderFactory,
): Promise<ExtensionProvider> => {
	const chartsExtensionPromise = createChartsExtension({
		// TODO: use intl5 instead
		intl: intl as any,
		extensionHandlers: extensionHandlers[
			EXTENSION_TYPE.MACRO_WITH_REFERENCES
		] as ExtensionHandlerWithReferenceFn<any>,
		createAnalyticsEvent,
	});

	const legacyManifests = await getMacroLegacyManifest(pageContext);
	const modernManifests: ExtensionManifest<any>[] = await mapLegacyManifestsToModernManifest(
		legacyManifests.macros,
		pageContext,
		extensionHandlers,
		getMigrationExtensionManifest,
		openMacroBrowserToEditExistingMacro,
		openMacroBrowserForInitialConfiguration,
		// We don't have the `editorAPI` for the renderer
		undefined,
		intl,
	);

	modernManifests.push(getRedactedMacroPlaceholderExtensionManifest(extensionHandlers));

	if (chartsExtensionPromise) {
		modernManifests.push(await chartsExtensionPromise);
	}

	// We don't want to check for the aiOptInStatus as we want to show the rendered block
	// regardless of AI status so we don't lose content if people downgrade etc
	const aiBlockProvider = await getAIPanelsProvider({
		intl: intl as any,
		providerFactory,
	});
	modernManifests.push(...(await aiBlockProvider.getExtensions()));

	const { contentId } = pageContext;

	if (fg('company_hub_spotlight_extension')) {
		const companyHubSpotlightProvider = await spotlightExtensionProvider({
			contentId,
			intl,
		});
		modernManifests.push(...(await companyHubSpotlightProvider.getExtensions()));
	}

	if (fg('company_hub_section_extension')) {
		const companyHubSectionProvider = await sectionExtensionProvider({
			contentId,
			intl,
		});
		modernManifests.push(...(await companyHubSectionProvider.getExtensions()));

		const hubElementPlaceholderProvider = await hubElementPlaceholderExtensionProvider({
			contentId,
			intl,
		});
		modernManifests.push(...(await hubElementPlaceholderProvider.getExtensions()));
	}

	if (fg('company_hub_search_extension')) {
		const companyHubSearchProvider = await searchExtensionProvider({
			intl,
			contentId,
		});
		modernManifests.push(...(await companyHubSearchProvider.getExtensions()));
	}

	const companyHubCarouselProvider = await carouselExtensionProvider({
		contentId,
		intl,
	});
	modernManifests.push(...(await companyHubCarouselProvider.getExtensions()));

	const companyHubLinkCardsProvider = await linkCardsExtensionProvider({
		contentId: pageContext.contentId,
		intl,
	});
	modernManifests.push(...(await companyHubLinkCardsProvider.getExtensions()));

	if (fg('company_hub_links_menu_extension')) {
		const customSitesLinksMenuProvider = await linksMenuExtensionProvider({
			contentId,
			intl,
		});
		modernManifests.push(...(await customSitesLinksMenuProvider.getExtensions()));
	}

	modernManifests.push(
		createDatabaseExtension({
			pageId: contentId,
			spaceKey: pageContext.spaceKey!,
			spaceId: pageContext.spaceId!,
			intl,
			createAnalyticsEvent,
			isEdit: false,
		}),
	);

	const { redactionExtension } = await import(
		/* webpackChunkName: "loadable-Redaction" */ './custom-extensions/Redaction'
	);

	if (fg('confluence_dlp_content_redaction')) {
		if (fg('dlp_cc-redactions-tour')) {
			modernManifests.push(createTourableRedactionExtension({ contentId }));
		} else {
			modernManifests.push(redactionExtension());
		}
	}

	if (fg('platform_editor_use_nested_table_pm_nodes')) {
		modernManifests.push(nestedTableExtension);
	}

	return new DefaultExtensionProvider(modernManifests);
};
