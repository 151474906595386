/**
 * What makes a good attribution? The answer is vertical name. While having an
 * attribution be other than your vertical name can be acceptable in certain
 * cases, the main purpose of attribution is to ensure the first responder is
 * picked correctly (e.g. on a team-by-team basis). In order to understand what
 * exact part of functionality is broken one would add experience tracking for
 * this functionality, not error boundary.
 */
export enum Attribution {
	INHERIT = 'inherit',

	//
	// Verticals:
	//
	// Not all attribution are using the enum due to circular dependency.
	// Check plain string `attribution: 'backbone'` or `attribution: 'backbone-` as well
	BACKBONE = 'backbone',
	BETTER_TOGETHER = 'better-together',
	COLLABORATION = 'collaboration',
	DISCO = 'disco',
	DLP = 'dlp',
	ECOSYSTEM = 'ecosystem',
	VIRALITY = 'virality',
	EXTERNAL_COLLAB = 'external-collaboration',
	GROWTH = 'growth',
	GROWTH_LUNA = 'growth-luna',
	GROWTH_MULTIPLIERS = 'growth-multipliers',
	GROWTH_STARBURST = 'growth-starburst',
	GROWTH_TAKO = 'growth-tako',
	GROWTH_TRANSMUTERS = 'growth-transmuters',
	GROWTH_TRAILBLAZERS = 'growth-traiblazers',
	CC_ONBOARDING = 'cc-onboarding',
	NAVX = 'navx',
	COMPANY_HUB = 'company-hub',
	TAILORED_EXPERIENCES = 'tailored-experiences',
	SMARTS = 'smarts',
	CC_ANALYTICS = 'cc-analytics',
	ECONOMY = 'economy',
	CONTENT_TYPES = 'content-types',
	CONTENPLATES = 'contenplates',
	AI_MATE = 'ai-mate',
	KNOWLEDGE_DISCOVERY = 'knowledge-discovery',
	STATSIG = 'statsig',
	AUTOMATION_FOR_CONFLUENCE = 'automation-for-confluence',
	PERMISSIONS_EXPERIENCE = 'permissions-experience',
	WHITEBOARDS = 'whiteboards',
	DATABASES = 'databases',
	ADMIN_EXPERIENCE = 'admin-experience',
	PAGE_EXPERIENCES = 'page-experiences',
	PAGE_EXTENSIONS = 'page-extensions',
	NOTIFICATIONS = 'cc-notifications',
	PAGE_MODES = 'page-modes',
	POST_OFFICE = 'post-office',
	COMMENTS = 'comments',
	TOPICS = 'topics',
	CC_INSIGHTS_AND_REPORTING = 'cc-insights-and-reporting',
	MIGRATIONS = 'migrations',
	CC_EDITOR_CORE_EXPERIENCES = 'cc-editor-core-experiences',
	EDITOR_CORE = 'editor-core',
}
