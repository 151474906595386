import { defineMessages } from 'react-intl-next';

import { fg } from '@confluence/feature-gating';

import type { FieldTransformer } from './types';

interface DetailsMacroParams {
	id?: string;
	hidden?: boolean;
}

const i18n = defineMessages({
	fieldIdLabel: {
		id: 'fabric-extension-lib.field-mappings.details.field.id.label',
		description:
			'Label for the ID field in the configuration panel for the Content properties (Page properties) macro, should align with macro name',
		defaultMessage: 'Content properties ID (optional)',
	},
	fieldIdDescription: {
		id: 'fabric-extension-lib.field-mappings.details.field.id.description',
		description:
			'Description below the ID field in the configuration panel for the Content properties (Page properties) macro',
		defaultMessage:
			'Specify an ID if you have multiple content properties macros on this page or doc and want to report on them separately.',
	},
	fieldHiddenLabel: {
		id: 'fabric-extension-lib.field-mappings.details.field.hidden.label',
		description:
			'Label for the Hidden field in the configuration panel for the Content properties (Page properties) macro',
		defaultMessage: 'Hide from view',
	},
	fieldHiddenDescription: {
		id: 'fabric-extension-lib.field-mappings.details.field.hidden.description',
		description:
			'Description below the Hidden field in the configuration panel for the Content properties (Page properties) macro. Content Properties Report macro refers to the new name of the Page Properties Report macro.',
		defaultMessage:
			'Hide macro contents from view, but continue to display them in the Content Properties Report macro.',
	},
});

export const details: FieldTransformer<DetailsMacroParams> = {
	transformFields: async (fields, _, intl) => {
		if (fg('confluence_live_pages_macro_copy_updates')) {
			return fields.map((field) => {
				if (field.name === 'id') {
					return {
						...field,
						label: intl.formatMessage(i18n.fieldIdLabel),
						description: intl.formatMessage(i18n.fieldIdDescription),
					};
				}
				if (field.name === 'hidden') {
					return {
						...field,
						label: intl.formatMessage(i18n.fieldHiddenLabel),
						description: intl.formatMessage(i18n.fieldHiddenDescription),
					};
				}

				return field;
			});
		}

		return fields;
	},
};
