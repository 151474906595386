import type { ProviderProps } from '@atlaskit/link-provider';

type SmartCardProviderFeatureFlags = ProviderProps['featureFlags'];

/**
 * React hook to obtain all SmartCardProvider feature flags.
 *
 * All LD FFs have been archived since March 31, 2025, this function will always return {}.
 * Once the featureFlags prop is removed from @atlaskit/link-provider SmartCardProvider component,
 * this hook can be removed.
 */
export const useSmartCardProviderFeatureFlags = (): SmartCardProviderFeatureFlags => {
	return {};
};
