let flags = typeof window !== 'undefined' ? (window as any)['__SSR_FEATURE_FLAGS__'] || {} : {};

export function initFeatureFlags(map = {}) {
	// Apply overrides from url query params
	flags = { ...map };
}

export function getSSRFeatureFlag(key: string): any {
	return key in flags ? flags[key] : null;
}

export function getAllSSRFeatureFlags() {
	return flags;
}
