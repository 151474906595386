import React from 'react';
import type { IntlShape } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';

import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';
import { token } from '@atlaskit/tokens';

import { LoadableAfterPaint } from '@confluence/loadable';

import {
	type CustomSitesExtensionProviderProps,
	getExtensionManifest,
} from '../shared-components/customSitesManifestBuilder';

import { type SectionParameters } from './sectionTypes';
import {
	DEFAULT_BACKGROUND_COLOR,
	DEFAULT_CONTENT_WIDTH,
	hubSectionExtensionType,
	getSectionExtensionContents,
	hubSectionExtensionKey,
} from './sectionConstants';

const SectionExtension = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SectionExtension" */ './SectionExtension'))
			.SectionExtension,
});

const i18n = defineMessages({
	title: {
		id: 'custom-sites-extensions.section.manifest.extension-title',
		defaultMessage: 'Section [New]',
		description: 'Title of section extension to be displayed in shortcut macros dropdown.',
	},
	lozengeTitle: {
		id: 'custom-sites-extensions.section.manifest.extension-lozenge-title',
		defaultMessage: 'Section',
		description: 'Title of section extension to be displayed in lozenge',
	},
	description: {
		id: 'custom-sites-extensions.section.manifest.macro-description',
		defaultMessage: 'Group elements together with a background',
		description:
			'Description of section extension to be displayed in toolbar/shortcut macros dropdown',
	},
	contentHeader: {
		id: 'custom-sites-extensions.section.manifest.content.header',
		defaultMessage: 'Give your section a title',
		description: "Header of section extension's initial content",
	},
	contentSubHeader: {
		id: 'custom-sites-extensions.section.manifest.content.sub-header',
		defaultMessage: 'Organize elements into sections with customizable backgrounds.',
		description: "Sub-header of section extension's initial content",
	},
	placeHolderTitle: {
		id: 'custom-sites-extensions.section.manifest.content.placeholder-title',
		defaultMessage: 'Placeholder',
		description:
			'Title of hub element placeholder extension to be displayed the macro is presented in the editor.',
	},
});

const sectionParameters = (intl: IntlShape): SectionParameters => ({
	extensionTitle: intl.formatMessage(i18n.lozengeTitle),
	backgroundColor: DEFAULT_BACKGROUND_COLOR,
	contentWidth: DEFAULT_CONTENT_WIDTH,
});

export const sectionExtensionProvider = async ({
	contentId,
	editorActions,
	openConfigPanel,
	intl,
}: CustomSitesExtensionProviderProps) => {
	const title = intl.formatMessage(i18n.title);
	const description = intl.formatMessage(i18n.description);
	const parameters = sectionParameters(intl);
	const type = hubSectionExtensionType;
	const key = hubSectionExtensionKey;
	const initialContentText = {
		header: intl.formatMessage(i18n.contentHeader),
		subHeader: intl.formatMessage(i18n.contentSubHeader),
		placeholderTitle: intl.formatMessage(i18n.placeHolderTitle),
	};

	const icons = {
		'48': () =>
			import(
				/* webpackChunkName: "loadable-atlaskitIconCorePremium" */ '@atlaskit/icon/core/premium'
			).then(({ default: PremiumIcon }) => () => (
				<PremiumIcon color={token('color.icon.subtle')} label="" spacing="spacious" />
			)),
	};

	const update = async () => {
		const selectedNode = editorActions?.getSelectedNode()?.toJSON();
		const selectedLocalId = selectedNode?.attrs?.localId;

		if (!!selectedLocalId && openConfigPanel) {
			openConfigPanel(selectedLocalId);
		}
	};

	const render =
		async () =>
		({ node, actions }) => {
			return (
				<SectionExtension
					contentId={contentId}
					extensionNode={node}
					editorActions={editorActions}
					mbeActions={actions}
				/>
			);
		};

	const additionalModuleNodeProps = {
		__allowBodiedOverride: true,
	};

	return new DefaultExtensionProvider([
		getExtensionManifest<SectionParameters>({
			key,
			icons,
			parameters,
			update,
			render,
			title,
			type,
			description,
			source: 'premiumSectionInsert',
			nodeType: 'multiBodiedExtension',
			quickInsertAction: async () =>
				Promise.resolve({
					type: 'multiBodiedExtension',
					key: 'hub-section',
					attrs: {
						extensionType: type,
						extensionKey: 'hub-section',
						layout: 'full-width',
						parameters,
					},
					content: getSectionExtensionContents(initialContentText),
				}),
			additionalModuleNodeProps,
		}),
	]);
};
