import { defineMessages } from 'react-intl-next';

import type { CustomField } from '@atlaskit/editor-common/extensions';

import { fg } from '@confluence/feature-gating';

import type { FieldTransformer } from './types';

const i18n = defineMessages({
	contentIncludeFieldLabel: {
		id: 'fabric-extension-lib.field-mappings.include.field.contentInclude.label',
		description:
			'New label for the "Page to Include" field in the Include Content macro configuration panel, accounts for all content types instead of just pages',
		defaultMessage: 'Content to include',
	},
});

export const include: FieldTransformer<any> = {
	transformFields: async (fields, _, intl) => {
		return fields.map((field) => {
			if (field.name !== 'defaultParameterValue') {
				return field;
			}

			return {
				...field,
				type: 'custom',
				label: fg('confluence_live_pages_macro_copy_updates')
					? intl.formatMessage(i18n.contentIncludeFieldLabel)
					: field.label,
				// description talks about entering page using SPACEKEY:Page_title syntax
				// which no longer applies as users are now just picking from a dropdown
				description: undefined,
				options: {
					resolver: {
						type: 'spaceKeyAndPageTitleContent',
					},
				},
			} as CustomField;
		});
	},
};
