type PK = string;
type PostOfficeData = unknown;
type PostOfficeFetcher = Promise<Response>;
type PostOfficeCacheData = {
	data: PostOfficeData | undefined;
	fetcher: PostOfficeFetcher | undefined;
};

export type PostOfficeCache = Record<PK, PostOfficeCacheData>;

export type ConfluenceGlobalThisWithPostOfficeCache = typeof globalThis & {
	__POST_OFFICE_QUERY_CACHE__: PostOfficeCache;
};

export class PostOfficeCacheClient {
	constructor(private cache: PostOfficeCache) {
		(globalThis as ConfluenceGlobalThisWithPostOfficeCache).__POST_OFFICE_QUERY_CACHE__ = cache;
	}

	private getKey(url: string): PK {
		const matches = url.match(/placements\/([\w\-\/]+)/);

		return matches?.[0] || url;
	}

	private get(key: PK): PostOfficeCacheData | undefined {
		return this.cache[key];
	}

	private set(key: PK, value: PostOfficeCacheData) {
		this.cache[key] = value;
	}

	getDataWithPlacementId(placementId: string): PostOfficeData | undefined {
		return this.get('placements/' + placementId)?.data;
	}

	setDataWithPlacementId(placementId: string, data: PostOfficeData) {
		this.set('placements/' + placementId, {
			data,
			fetcher: undefined,
		});
	}

	getDataWithUrl(url: string): PostOfficeData | undefined {
		return this.get(this.getKey(url))?.data;
	}

	setDataWithUrl(url: string, data: PostOfficeData) {
		const cache = this.get(this.getKey(url));
		this.set(this.getKey(url), {
			fetcher: cache?.fetcher,
			data,
		});
	}

	getFetcherWithUrl(url: string): PostOfficeFetcher | undefined {
		return this.get(this.getKey(url))?.fetcher;
	}

	setFetcherWithUrl(url: string, fetcher: PostOfficeFetcher) {
		const cache = this.get(this.getKey(url));
		this.set(this.getKey(url), {
			data: cache?.data,
			fetcher,
		});
	}
}
