import memoize from 'memoize-one';

import { getUniquePageLoadId } from '@confluence/unique-page-load-id';
import { fg } from '@confluence/feature-gating';

import type {
	ExperienceAttributes,
	ExperienceEvent,
	StartEvent,
	StopEvent,
} from './ExperienceEvent';
import { ExperienceTimeoutError } from './ExperienceTimeoutError';
import { getTabInactivityTracker } from './TabInactivityTracker';
import { getFreezeTracker } from './FreezeTracker';
import type { ExperienceStopStates } from './ExperienceStopStates';
import {
	CREATE_PAGE_EXPERIENCE,
	VIEW_CONTENT_EXPERIENCE,
	VIEW_PAGE_EXPERIENCE,
	VIEW_PAGE_SESSION_EXPERIENCE,
} from './ExperienceName';

type ConstructorProps = {
	name: string;
	id: string;
	timeout?: number;
	startTime?: number;
	attributes?: ExperienceAttributes;
	onStart?: (event: StartEvent) => (event: StopEvent) => void;
	onSuccess?: () => void;
	onFailure?: () => void;
	onAbort?: () => void;
};

type ExperienceStopStateKeys = keyof typeof ExperienceStopStates;
type ExperienceStopState = (typeof ExperienceStopStates)[ExperienceStopStateKeys];

export type ExperienceState = {
	taskId: string;
	name: string;
	timeout?: number;
	hasStopped: boolean;
	stopState: ExperienceStopState | null;
	startTime: number;
	attributes?: ExperienceAttributes;
};

export type DebugPoint = {
	timestamp: string;
	now: number;
	message: string;
	attributes: ExperienceAttributes;
};

// Whenever we want to target view-page experience, we should ensure we're targeting all correlated view-page experiences.
// This is more complete and helps avoid bugs related to view-content experience forwarding to view-page.
const VIEW_PAGE_EXPERIENCES = [
	VIEW_PAGE_EXPERIENCE,
	VIEW_CONTENT_EXPERIENCE,
	VIEW_PAGE_SESSION_EXPERIENCE,
];
// Experience timeouts by default are considered taskFails. Certain experiencs should instead treat timeouts as aborts, to reduce unnecessary noise and improve SLI accuracy.
const EXPERIENCE_TIMEOUTS_TO_ABORT = [CREATE_PAGE_EXPERIENCE, ...VIEW_PAGE_EXPERIENCES];

// Errors that are thrown by client side and can be ignored, this is to avoid treating these as fails that can impact SLOs.
// ideally we can show the user a flag that shows something is wrong, please avoid adding to this list unless absolutely necessary.
const IGNORABLE_CLIENT_SIDE_ERRORS = ["can't access dead object"];

export class Experience {
	name: string;
	id: string;
	timeout?: number;

	private freezeTime = 0;
	private stopState: ExperienceStopState | null = null;
	private startTime: number;
	private attributes?: ExperienceAttributes;
	private onStop?: (event: StopEvent) => void;
	private onSuccess?: () => void;
	private onFailure?: () => void;
	private onAbort?: () => void;
	private debugPoints: DebugPoint[] = [];

	constructor({
		name,
		id,
		timeout,
		startTime = window.performance.now(),
		attributes,
		onStart,
		onSuccess,
		onFailure,
		onAbort,
	}: ConstructorProps) {
		this.name = name;
		this.id = id;
		this.timeout = timeout;
		this.startTime = startTime;
		this.attributes = attributes;
		this.onSuccess = onSuccess;
		this.onFailure = onFailure;
		this.onAbort = onAbort;

		const startEvent: StartEvent = {
			action: 'taskStart',
			name,
			id,
			startTime,
			timeout,
			attributes: {
				...getUniquePageLoadId(),
				...attributes,
				...this.getFeatureGateAttributes(),
			},
		};
		if (onStart) {
			this.onStop = onStart(startEvent);
		}
		getFreezeTracker().subscribe(name, (time) => {
			this.freezeTime += time;
		});
	}

	succeed(attributes?: ExperienceAttributes) {
		if (this.hasStopped) return;

		this.onSuccess && this.onSuccess();

		this.stop({
			action: 'taskSuccess',
			name: this.name,
			id: this.id,
			startTime: this.startTime,
			duration: this.getAbsoluteDuration(),
			activeDuration: this.getDurationAdjustedForActive(),
			adjustedDuration: this.getDurationAdjustedForTabActivity(),
			attributes: {
				...getUniquePageLoadId(),
				...this.getFeatureGateAttributes(),
				...this.attributes,
				...attributes,
			},
		});
	}

	fail({ error, attributes }: { error: Error; attributes?: ExperienceAttributes }) {
		if (this.hasStopped) return;

		if (error?.message && IGNORABLE_CLIENT_SIDE_ERRORS.includes(error.message)) {
			this.abort({
				reason: 'Aborted because of client side error',
				attributes: {
					...attributes,
					clientSideError: error.message,
				},
			});
			return;
		}

		this.onFailure && this.onFailure();

		this.stop({
			action: 'taskFail',
			name: this.name,
			id: this.id,
			startTime: this.startTime,
			duration: this.getAbsoluteDuration(),
			activeDuration: this.getDurationAdjustedForActive(),
			adjustedDuration: this.getDurationAdjustedForTabActivity(),
			debugPoints: this.debugPoints,
			error,
			attributes: {
				...getUniquePageLoadId(),
				...this.getFeatureGateAttributes(),
				...this.attributes,
				...attributes,
			},
		});
	}

	abort({
		reason,
		attributes,
		checkForTimeout = true,
	}: {
		reason: string;
		attributes?: ExperienceAttributes;
		checkForTimeout?: boolean;
	}) {
		if (this.hasStopped) return;

		const adjustedDuration = this.getDurationAdjustedForTabActivity();
		const isTimeout = checkForTimeout && this.timeout != null && adjustedDuration >= this.timeout;

		// Check if the experience should have failed due to timeout
		if (isTimeout) {
			// We want to abort timeouts from view-page and create-page to improve SLO reliability accuracy as they're not guaranteed to be correlated to real user impact. For more info see MODES-4806.
			if (!EXPERIENCE_TIMEOUTS_TO_ABORT.includes(this.name)) {
				this.fail({
					attributes: {
						originalAbortReason: reason,
						...attributes,
					},
					error: new ExperienceTimeoutError(`${this.name} failed to complete in ${this.timeout}ms`),
				});
				if (process.env.NODE_ENV === 'development') {
					// eslint-disable-next-line no-console
					console.warn(
						`%cExperience Timeout: ${this.name} failed to complete in ${this.timeout}ms`,
						`color: #FF0000; font-size: 20px; font-weight: bold;`,
					);
					// eslint-disable-next-line no-console
					console.warn(
						`%cWhile timeout can happen due to genuine reasons, often this is an indication that experience is started and NOT stopped. If you see this error, please ensure that mentioned experience is stopped correctly.`,
						`color: #FF0000;`,
					);
				}

				return;
			}
		}

		this.onAbort && this.onAbort();

		this.stop({
			action: 'taskAbort',
			name: this.name,
			id: this.id,
			startTime: this.startTime,
			duration: this.getAbsoluteDuration(),
			activeDuration: this.getDurationAdjustedForActive(),
			adjustedDuration,
			reason: isTimeout
				? `ExperienceTimeout: ${this.name} failed to complete in ${this.timeout}ms`
				: reason,
			checkForTimeout,
			attributes: {
				originalAbortReason: isTimeout ? reason : undefined,
				...getUniquePageLoadId(),
				...this.getFeatureGateAttributes(),
				...this.attributes,
				...attributes,
			},
		});
	}

	/**
	 * Called on experience to stop it with the same action (success / failure / abort) and attributes as another event.
	 * For example, compound experience (e.g. edit-page) is failed if sub-experience (e.g. edit-page/publish) fails.
	 */
	stopOn(event?: ExperienceEvent, extraAttributes: ExperienceAttributes = {}) {
		if (!event) {
			return;
		}

		if (event.action === 'taskSuccess') {
			this.succeed({});
		} else if (event.action === 'taskAbort') {
			this.abort({
				reason: event.reason,
				attributes: {
					...extraAttributes,
					stoppedOn: event.name,
					stoppedOnPath: this.getStoppedOnPath(event),
				},
				checkForTimeout: event.checkForTimeout,
			});
		} else if (event.action === 'taskFail') {
			this.fail({
				error: event.error,
				attributes: {
					...extraAttributes,
					stoppedOn: event.name,
					stoppedOnPath: this.getStoppedOnPath(event),
				},
			});
		}
	}

	updateAttributes(attributes: object) {
		this.attributes = { ...this.attributes, ...attributes };
	}

	attachDebugPoint(message: string, attributes: ExperienceAttributes = {}) {
		this.debugPoints.push({
			timestamp: new Date().toISOString(),
			now: window.performance.now(),
			message,
			attributes,
		});
	}

	getState(): ExperienceState {
		return {
			taskId: this.id,
			timeout: this.timeout,
			hasStopped: this.hasStopped,
			stopState: this.stopState,
			startTime: this.startTime,
			name: this.name,
			attributes: this.attributes,
		};
	}

	get hasStopped() {
		return this.stopState !== null;
	}

	private stop(event: StopEvent) {
		this.stopState = event.action;
		if (this.onStop) {
			this.onStop(event);
		}
		getFreezeTracker().unsubscribe(this.name);
	}

	private getAbsoluteDuration() {
		return Math.round(window.performance.now() - this.startTime);
	}

	private getDurationAdjustedForTabActivity() {
		return (
			this.getAbsoluteDuration() -
			getTabInactivityTracker().getInactiveMillisecondsSince(this.startTime)
		);
	}

	private getDurationAdjustedForActive() {
		return this.getAbsoluteDuration() - this.freezeTime;
	}

	private getStoppedOnPath(event: ExperienceEvent) {
		if (!event.attributes?.stoppedOnPath) {
			return event.name;
		}
		return `${event.name},${event.attributes.stoppedOnPath}`;
	}

	// Include certain broad-impact feature gates in experience events
	private getFeatureGateAttributes = memoize(() => {
		return {
			isContentWrapperEnabled: fg('confluence_frontend_content_wrapper'),
			isNav4Enabled: fg('confluence_nav4'),
		};
	});
}
