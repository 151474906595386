export interface GateOverridesType {
	[key: string]: boolean;
}

const getQueryParam = (url?: string): any => {
	// eslint-disable-next-line no-restricted-syntax
	if (!url && !window.location.href) {
		return {};
	}
	// eslint-disable-next-line no-restricted-syntax
	const generatedUrl = new URL(url || window.location.href);
	return new URLSearchParams(generatedUrl.search);
};

function parseParamGate(gate: string): [string, string | boolean] {
	const [rawGateName, rawGateValue] = gate.split(':');

	// Set default gate value to true
	let gateValue: boolean | string = rawGateValue ?? 'true';

	// Convert string values to real boolean values.
	if (gateValue.toLowerCase() === 'true') {
		gateValue = true;
	} else if (gateValue.toLowerCase() === 'false') {
		gateValue = false;
	}

	return [rawGateName, gateValue];
}

/*
 * Initialize overriding of feature gates based on query parameters from the URL
 * Multiple FF can be specified with ?ff=<name>:<value>,<other name>:<other value>, e.g.
 * ?ff=confluence_frontend_enable_feature:true,confluence_frontend_enable_other_feature:false
 * For convenience, the value may be omitted (defaults to true):
 * ?ff=confluence_frontend_enable_feature is equivalent to ?ff=confluence_frontend_enable_feature:true
 * Note: We only return the overrides here (rather than all of the feature gates)
 */
export const processQueryParamFeatureGates = (url?: string): GateOverridesType => {
	const queryParams = getQueryParam(url);

	if (!queryParams?.get || !queryParams.get('fg')) {
		return {};
	}

	const paramGates = queryParams.get('fg').split(',');
	const urlOverrides: GateOverridesType = {};
	paramGates.forEach((gate: string) => {
		const [gateName, gateValue] = parseParamGate(gate);

		// filter out feature gates with non-boolean values
		if (typeof gateValue === 'boolean') {
			urlOverrides[gateName] = gateValue;
		}
	});
	return urlOverrides;
};
