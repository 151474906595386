import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import { SpaceDetailQuery } from './SpaceDetailQuery.graphql';

export type SpaceDetailDataType = {
	spaceId: string;
	type: string;
	name: string;
	alias?: string;
	dataClassificationTags: string[];
};

interface CommonHookResult {
	loading: boolean;
	error?: ApolloError;
}

export interface SpaceDetailType extends CommonHookResult {
	data?: SpaceDetailDataType;
}

export const useSpaceDetail = (spaceKey: string | null | undefined): SpaceDetailType => {
	const { loading, data, error } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceDetailQuery,
		{
			variables: {
				spaceKey,
			},
			fetchPolicy: 'cache-first',
		},
	);

	return {
		loading,
		data: data && {
			spaceId: data.space?.id,
			type: data.space?.type,
			name: data.space?.name,
			dataClassificationTags: data.space?.dataClassificationTags,
			alias: data.space?.alias,
		},
		error,
	};
};
