import memoize from 'memoize-one';

import type { Flags, FlagShape } from '@atlaskit/feature-flag-client/types';
import { setBooleanFeatureFlagResolver } from '@atlaskit/platform-feature-flags';
import { setupEditorExperiments } from '@atlaskit/tmp-editor-statsig/setup';

import { createFeatureFlagClient } from '@confluence/feature-flag-client';
import { fg } from '@confluence/feature-gating';

import { applyOverrides } from './FeatureFlagLocalOverrides';
import { processRolloutFeatureFlags } from './processRolloutFeatureFlags';
import { unwrapValue, keyById, processFeatureFlagsPure } from './processFeatureFlagsPure';
import type {
	FeatureFlagsQuery_featureFlags_nodes,
	FeatureFlagsQuery_platformFeatureFlags_nodes,
} from './__types__/FeatureFlagsQuery';

const setOverridesWithDetail = (
	key: string,
	overrides: { [key: string]: string | boolean },
	original: Flags,
): FlagShape => {
	if (overrides.hasOwnProperty(key)) {
		return {
			...original[key],
			value: overrides[key],
		};
	}

	return original[key];
};

function normalizeValue(rawValue: FeatureFlagsQuery_featureFlags_nodes): FlagShape {
	if (typeof rawValue === 'object' && 'value' in rawValue) {
		return {
			...(rawValue.explanation ? { explanation: { ...(rawValue.explanation as any) } } : {}),
			value: unwrapValue(rawValue.value),
		};
	} else {
		return {
			value: unwrapValue(rawValue),
			explanation: undefined,
		};
	}
}

export const processFeatureFlags = memoize(
	(
		flags: FeatureFlagsQuery_featureFlags_nodes[],
		platformFlags: FeatureFlagsQuery_platformFeatureFlags_nodes[],
		userId?: string | null,
	) => {
		const normalizedFeatureFlags: Flags = flags ? keyById(flags, normalizeValue) : {};

		const normalizedPlatformFeatureFlags: Flags =
			platformFlags != null ? keyById(platformFlags, normalizeValue) : {};

		// Feature flag overrides are now persisted to the server (via storage-manager),
		// and are applied AFTER storage-manager has been initialized. While our devs
		// get used to this change, we still want to apply any overrides they've already
		// set into local storage. Those local overrides will only be used in SPA,
		// as SSR doesn't have access to local storage.
		const featureFlagsWithOverridesAndDetails = applyOverrides<FlagShape>(
			Object.assign(normalizedFeatureFlags, normalizedPlatformFeatureFlags),
			setOverridesWithDetail,
		);
		const featureFlagClient = createFeatureFlagClient();
		featureFlagClient.setFlags(featureFlagsWithOverridesAndDetails);

		// This sets up tooling for the editor experiments
		setupEditorExperiments('confluence');

		// This sets up the platform feature flags
		setBooleanFeatureFlagResolver((flagKey) => {
			// eslint-disable-next-line confluence-feature-gating/static-feature-gates
			if (fg(flagKey)) {
				return true;
			}

			if (
				featureFlagClient.getBooleanValue(flagKey, {
					default: false,
					shouldTrackExposureEvent: true,
				})
			) {
				return true;
			}

			return false;
		});

		const allFlags = flags.concat(platformFlags);

		const { original, withOverrides } = processFeatureFlagsPure(allFlags);

		// process ff rollout with specific userIds in mind
		void processRolloutFeatureFlags(withOverrides, userId);
		// Process FF overrides in URL query params. This runs in both SSR and SPA,
		// and allows those overrides to be used in the initial render. In SPA, we
		// will persist these FF overrides after storage-manager is initialized.

		return {
			originalFeatureFlags: original,
			featureFlagsWithOverrides: { ...withOverrides },
			featureFlagClient,
		};
	},
);
