import { useMemo } from 'react';

import type { ADFEntity } from '@atlaskit/adf-utils/types';
import type { ExtensionHandlers } from '@atlaskit/editor-common/extensions';
import type { ExtensionPluginOptions } from '@atlaskit/editor-plugin-extension';

import { is1PMacro } from '../extensions-common/macroClassificationUtils';

export type useExtensionRendererViewOptionsProps = {
	isLivePage: boolean;
	contentId: string;
	spaceKey: string;
	getExtensionHandlers?: (options: any) => ExtensionHandlers;
};

export const is1PBodiedExtension = ({
	extensionKey,
	extensionType,
}: {
	extensionKey?: string;
	extensionType?: string;
}) => is1PMacro(extensionKey || '') && extensionType === 'bodiedExtension';

export const is2PBodiedExtension = ({
	extensionKey,
	extensionType,
}: {
	extensionKey?: string;
	extensionType?: string;
}) => !is1PMacro(extensionKey || '') && extensionType === 'bodiedExtension';

export const useExtensionRendererViewOptions = ({
	getExtensionHandlers,
	isLivePage,
	contentId,
	spaceKey,
}: useExtensionRendererViewOptionsProps): ExtensionPluginOptions['__rendererExtensionOptions'] => {
	return useMemo(() => {
		if (isLivePage) {
			return {
				rendererExtensionHandlers: getExtensionHandlers?.({
					contentId,
					spaceKey,
					isInEditorRendererView: true,
				}),
				isAllowedToUseRendererView: (node: ADFEntity) =>
					is2PBodiedExtension({
						extensionKey: node.attrs?.extensionKey,
						extensionType: node.type,
					}),
				showUpdated1PBodiedExtensionUI: (node: ADFEntity) =>
					is1PBodiedExtension({
						extensionKey: node.attrs?.extensionKey,
						extensionType: node.type,
					}),
			};
		}
		return undefined;
	}, [getExtensionHandlers, isLivePage, contentId, spaceKey]);
};
