import { defineMessages, type IntlShape } from 'react-intl-next';

import { fg } from '@confluence/feature-gating';

import { EXTENSION_KEY, MACROS_WITH_LIVE_DOC_REFERENCE } from '../extensionConstants';

const i18n = defineMessages({
	anchorMacroTitle: {
		id: 'fabric-extension-lib.anchor.title',
		description:
			'Title text to be shown for anchor macro in the macro browser, quick insert menu, and config panel',
		defaultMessage: 'Anchor link',
	},
	anchorMacroDescription: {
		id: 'fabric-extension-lib.anchor.description',
		description:
			'Description text to be shown for anchor macro in the macro browser, quick insert menu, and config panel',
		defaultMessage: 'Create a link to anywhere',
	},
	childrenMacroTitle: {
		id: 'fabric-extension-lib.children.live-docs.title',
		description:
			'New title for "Child pages" macro to account for both pages and live docs content types. "Child items" is the new macro name, and "(Child pages)" is the original macro name in parentheses.',
		defaultMessage: 'Child items (Child pages)',
	},
	childrenMacroDescription: {
		id: 'fabric-extension-lib.children.live-docs.description',
		description:
			'Description for Child items (Child pages) macro to account for both pages and live docs content types.',
		defaultMessage: 'Display a custom list of content nested under a page or live doc.',
	},
	detailsMacroTitle: {
		id: 'fabric-extension-lib.details.live-docs.title',
		description:
			'New title for Page properties macro to account for both pages and live docs content types. "Content properties" is the new macro name, and "(Page properties)" is the original macro name in parentheses.',
		defaultMessage: 'Content properties (Page properties)',
	},
	detailsMacroDescription: {
		id: 'fabric-extension-lib.details.live-docs.description',
		description:
			'Description for Content properties (Page properties) macro to account for both pages and live docs content types.',
		defaultMessage:
			'Enter a table of summary information in this macro and display it on another page or live doc using a content properties report macro. You will need to add a label to this content and specify it in the report macro.',
	},
	pageTreeMacroTitle: {
		id: 'fabric-extension-lib.pagetree.live-docs.title',
		description:
			'New title for Page tree macro to account for multiple content types. "Content tree" is the new macro name, and "(Page tree)" is the original macro name in parentheses.',
		defaultMessage: 'Content tree (Page tree)',
	},
	pageTreeMacroDescription: {
		id: 'fabric-extension-lib.pagetree.live-docs.description',
		description:
			'Description for Content tree (Page tree) macro to account for multiple content types.',
		defaultMessage: 'Display a full or partial content tree.',
	},
	includeMacroTitle: {
		id: 'fabric-extension-lib.include.live-docs.title',
		description:
			'New title for Include Page macro to account for multiple content types. "Include content" is the new macro name, and "(Include page)" is the original macro name in parentheses.',
		defaultMessage: 'Include content (Include page)',
	},
	includeMacroDescription: {
		id: 'fabric-extension-lib.include.live-docs.description',
		description:
			'Description for Include content (Include page) macro to account for both page and live doc content types.',
		defaultMessage: 'Display the full contents of a page or live doc.',
	},
	detailsSummaryMacroTitle: {
		id: 'fabric-extension-lib.details-summary.live-docs.title',
		description:
			'New title for Page properties report macro to account for multiple content types. "Content properties report" is the new macro name, and "(Page properties report)" is the original macro name in parentheses.',
		defaultMessage: 'Content properties report (Page properties report)',
	},
	detailsSummaryMacroDescription: {
		id: 'fabric-extension-lib.details-summary.live-docs.description',
		description:
			'Description for Content properties report (Page properties report) macro to account for both page and live doc content types.',
		defaultMessage:
			'Display a table of pages and live docs that contain a content properties macro and a specific label. The table includes a link to each item and the summary from the macro on that page or doc.',
	},
});

export const getMacroTitleOverride = (extensionKey: string, intl: IntlShape): string | null => {
	// Override new macro title for anchor macro
	if (extensionKey === EXTENSION_KEY.ANCHOR) {
		return intl.formatMessage(i18n.anchorMacroTitle);
	}

	if (
		MACROS_WITH_LIVE_DOC_REFERENCE.includes(extensionKey) &&
		fg('confluence_live_pages_macro_copy_updates')
	) {
		switch (extensionKey) {
			case EXTENSION_KEY.CHILDREN:
				return intl.formatMessage(i18n.childrenMacroTitle);

			case EXTENSION_KEY.DETAILS:
				return intl.formatMessage(i18n.detailsMacroTitle);

			case EXTENSION_KEY.PAGE_TREE:
				return intl.formatMessage(i18n.pageTreeMacroTitle);

			case EXTENSION_KEY.INCLUDE:
				return intl.formatMessage(i18n.includeMacroTitle);

			case EXTENSION_KEY.DETAILS_SUMMARY:
				return intl.formatMessage(i18n.detailsSummaryMacroTitle);
		}
	}

	return null;
};

export const getMacroDescriptionOverride = (
	extensionKey: string,
	intl: IntlShape,
): string | null => {
	// Override new macro title and description for anchor macro
	if (extensionKey === EXTENSION_KEY.ANCHOR) {
		return intl.formatMessage(i18n.anchorMacroDescription);
	}

	// PGXT-7617 Update macro strings referencing page content types to also include live docs content types
	if (
		MACROS_WITH_LIVE_DOC_REFERENCE.includes(extensionKey) &&
		fg('confluence_live_pages_macro_copy_updates')
	) {
		switch (extensionKey) {
			case EXTENSION_KEY.CHILDREN:
				return intl.formatMessage(i18n.childrenMacroDescription);

			case EXTENSION_KEY.DETAILS:
				return intl.formatMessage(i18n.detailsMacroDescription);

			case EXTENSION_KEY.PAGE_TREE:
				return intl.formatMessage(i18n.pageTreeMacroDescription);

			case EXTENSION_KEY.INCLUDE:
				return intl.formatMessage(i18n.includeMacroDescription);

			case EXTENSION_KEY.DETAILS_SUMMARY:
				return intl.formatMessage(i18n.detailsSummaryMacroDescription);
		}
	}

	return null;
};
