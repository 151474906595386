import { fg } from '@confluence/feature-gating';
import { expVal } from '@confluence/feature-experiments';

import { useNav4OptOut } from './useNav4OptOut';
import { getNav4GAPhase } from './getNav4GAPhase';
import { useGetNav4OptIn } from './useGetNav4OptIn';

// eslint-disable-next-line confluence-feature-gating/inline-usage
export const useIsNav4Enabled = () => {
	const currentGAPhase = getNav4GAPhase();
	const { nav4AdminOptedIn } = useGetNav4OptIn();
	const { nav4OptOut } = useNav4OptOut();

	if (currentGAPhase === 2) {
		return true;
	}

	if (nav4OptOut) {
		if (
			currentGAPhase === 1 && //if we're in phase 1
			nav4AdminOptedIn && //and the whole product is opted into nav4
			!fg('confluence_nav_4_phase_1_opt_out')
		) {
			//and two way door is not enabled
			return true; //ignore user's opt out status and show nav4
		}
		return false;
	}

	return (
		fg('confluence_nav_4') ||
		expVal('confluence_jira_nav4_new_customers', 'willShowNav4', false) ||
		expVal('confluence_nav_4_free', 'isEnabled', false) ||
		// nav4OptOut === false means the user has explicitly opted in for themselves
		(currentGAPhase === 1 && (nav4AdminOptedIn || nav4OptOut === false))
	);
};
